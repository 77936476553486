import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import diagram from '../diagram.png'
import {
  Navbar,
  Notification,
  NotificationInfo,
  NotificationType,
  OrderStatusBadge,
} from '../Components'
import { CheckoutSession, OrderStatus } from '../Types'

import ShineBorder from '../Components/ShineBorder'
import { useInterval } from '../Hooks'

export default function Order() {
  const { orderId } = useParams()

  const [notification, setNotification] = useState<NotificationInfo>()
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>()

  async function getCheckoutSession() {
    try {
      const url = `https://api.birdwallet.xyz/v2/checkout_session/${orderId}`

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })

      if (!response.ok) {
        // Handle non-2xx status codes
        console.error(`Error: ${response.statusText}`)
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()

      /*
       * Payer action required
       */
      if (
        data.status === OrderStatus.PAYER_ACTION_REQUIRED &&
        checkoutSession?.status === OrderStatus.CREATED
      ) {
        setNotification({
          message: 'Awaiting user input',
          type: NotificationType.info,
        })
        setTimeout(() => {
          setNotification(undefined)
        }, 3000)
      }

      /*
       * Shipping address zipcode up date
       */

      setCheckoutSession(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getCheckoutSession()
  }, [])

  useInterval(() => {
    getCheckoutSession()
  }, 3000)

  const selectedShippingMethod = checkoutSession?.shipping_methods?.find(
    (method) => method.selected,
  )

  if (!checkoutSession) {
    return <div className="text-center py-10">Expired</div>
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <div>
      <Navbar />
      {!!notification && (
        <Notification
          notification={notification}
          onClose={() => setNotification(undefined)}
        />
      )}

      <div className="container mx-auto max-w-4xl flex items-center">
        <img
          alt={checkoutSession.merchant.name}
          src={checkoutSession.merchant.logo}
          className="w-10 h-10 object-cover rounded-md shadow-md mr-6"
        />

        <div className="flex flex-col mr-8">
          <h3 className="text-xl">Pay {checkoutSession.merchant.name}</h3>
          <p className="text-sm text-gray-500 mt-2">
            {checkoutSession.merchant.email}
          </p>
          <p className="text-sm text-gray-500">
            {checkoutSession.merchant.phone}
          </p>
          <p className="text-sm text-gray-500">
            {checkoutSession.merchant.url}
          </p>
        </div>

        <div className="flex flex-col">
          <p>{checkoutSession.merchant.business_address.line1}</p>
          <p>{checkoutSession.merchant.business_address.line2}</p>
          <p>{checkoutSession.merchant.business_address.city}</p>
          <p>{checkoutSession.merchant.business_address.state}</p>
          <p>{checkoutSession.merchant.business_address.postal_code}</p>
          <p>{checkoutSession.merchant.business_address.country}</p>
        </div>
      </div>

      <div className="container mx-auto p-4 flex max-w-4xl mt-10">
        <div className="w-1/2">
          <h1 className="text-2xl font-bold mb-4">Order Details</h1>

          <div className="bg-white shadow-md rounded-lg p-6 mb-4">
            <h2 className="text-xl font-semibold mb-2">Total Amount</h2>
            <div className="flex justify-between">
              <p className="text-lg text-gray-700">
                {formatter.format(parseFloat(checkoutSession.total.value))}
              </p>

              <p className="text-sm text-gray-700">
                <OrderStatusBadge status={checkoutSession.status} />
              </p>
            </div>
          </div>

          {/*
           * Summary
           */}
          <div className="bg-white shadow-md rounded-lg px-6 pt-6 pb-4">
            <h2 className="text-xl font-semibold mb-4">Summary</h2>
            <ul>
              {checkoutSession.line_items?.map((item, index) => (
                <li key={index} className="mb-5">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      {item.type === 'item' && item.thumbnail_url && (
                        <img
                          className="w-8 h-8 rounded mr-4"
                          src={item.thumbnail_url}
                          alt={item.label}
                        />
                      )}

                      <span className="text-md">{item.label}</span>
                    </div>

                    {item.status === 'pending' ? (
                      <span className="text-xs text-gray-700">Pending</span>
                    ) : (
                      <span className="text-lg text-gray-700">
                        {formatter.format(parseFloat(item.value))}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {/*
           * Shipping Methods
           */}
          {/*selectedShippingMethod && (
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-xl font-semibold mb-2">Shipping Method</h2>

              <div className="flex justify-between items-center">
                <span className="text-lg font-medium">
                  {selectedShippingMethod.label}
                </span>
                <span className="text-lg text-gray-700">
                  {formatter.format(parseFloat(selectedShippingMethod.amount))}
                </span>
              </div>

              <p className="text-sm text-gray-500">
                {selectedShippingMethod.detail}
              </p>
            </div>
          )*/}
        </div>

        <div className="w-1/2 mt-10">
          <div className="flex flex-col items-center justify-center p-4 rounded-lg">
            <ShineBorder
              className="relative p-4 rounded-lg border bg-white md:shadow-sm"
              color={/*['#A07CFE', '#FE8FB5', '#FFBE7B']*/ '#eee'}
            >
              <img
                src={`https://api.birdwallet.xyz/v2/barcode/qr?data=${encodeURIComponent(
                  `https://pay.birdwallet.xyz/order/${orderId}`,
                )}`}
                alt="QR Code"
                className="w-48 h-48 p-2 object-cover rounded-md shadow-md"
              />
            </ShineBorder>

            <img className="w-40 h-40 object-cover mt-8" src={diagram} />

            <p className="mt-10 text-center text-gray-700 px-8">
              Open the wallet on your phone and scan to continue payment
            </p>

            <a
              href="https://example.com/download"
              className="text-blue-500 hover:underline mt-8"
            >
              Download App
            </a>
          </div>
        </div>
      </div>

      {/*<div className="container mx-auto p-4 flex max-w-4xl mt-10">
        <p className="">
          Your total comes to $112.00 USD. This includes $100.00 for the iPhone,
          $10.00 for sales tax, and $2.00 for shipping. Everything is currently
          pending.
        </p>
      </div>*/}
    </div>
  )
}
